import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import axios from 'axios';

import "@/assets/normalize.css";//清除默认样式
import "@/assets/components.css"//icon图标
import ElementUI from 'element-ui';// 引入elementUI组件
import 'element-ui/lib/theme-chalk/index.css';// 引入elementUI组件 的全局样式
import quanju from './common/index.js'//注册全局公共样式
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'


Vue.use(ElementUI);
Vue.use(preview)
for(let i in quanju){
  //执行全局注册
  Vue.component(i,quanju[i])
}


new Vue({
  router,
  store,
  data:{
    title:'Agri'
  },
  render: function (h) { return h(App) }
}).$mount('#app')
