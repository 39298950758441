<template>
  <div class="container">
    <div class="person-wrap">
      <!-- <transition name="slide" mode="out-in"> -->
        <div class="person-list" :key="currentPage" ref="personTab">
          <div v-for="(item, index) in visibleItems" :key="index" class="col">
            {{ item }}
          </div>
        </div>
      <!-- </transition> -->
    </div>
    <div class="arrow left" @click="scrollLeft">&lt;</div>
    <div class="arrow right" @click="scrollRight">&gt;</div>
    <div class="dots">
      <span
        v-for="(_, index) in totalPages"
        :key="index"
        class="dot"
        :class="{ active: index === currentPage }"
        @click="gotoPage(index)"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: Array.from({ length: 20 }, (_, i) => `Item ${i + 1}`),
      visibleItems: [],
      itemsPerPage:10, // Number of items per page
      currentPage: 0
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    }
  },
  methods: {
    scrollLeft() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.updateVisibleItems();
      }
    },
    scrollRight() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.updateVisibleItems();
      }
    },
    updateVisibleItems() {
      const startIdx = this.currentPage * this.itemsPerPage;
      this.visibleItems = this.items.slice(startIdx, startIdx + this.itemsPerPage);
    },
    gotoPage(page) {
      if (page >= 0 && page < this.totalPages) {
        this.currentPage = page;
        this.updateVisibleItems();
      }
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$nextTick(() => {
          this.$refs.personTab.style.transition = "transform 0.5s";
        });
      }
    }
  },
  mounted() {
    this.updateVisibleItems();
  }
};
</script>

<style>
  .container {
    position: relative;
    overflow: hidden;
  }
  .person-wrap {
    display: flex;
    overflow: hidden;
  }
  .person-list {
    display: flex;
  }
  .col {
    width: 180px;
    height: 100px;
    margin-right: 20px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s;
  }
  .arrow {
    position: absolute;
    top: 50%;
    font-size: 24px;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
  }
  .dot.active {
    background-color: #333;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s;
  }
  .slide-enter, .slide-leave-to {
    transform: translateX(100%);
  }
</style>
