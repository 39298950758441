//引入菜单列表数据
import {specslist,specscount} from '../../../requers/api'
const state = {
    spelist:[],
    spezongye:0,// 总条数
    spepage:1,//当前页
    spesize:3,//当前页显示多少条数据
}
const getters = {
    getSpe(state){
        return state.spelist
    },
    getZy(state){
        return state.spezongye
    },
    getSize(state){
        return state.spesize
    },
    getPages(state){
        return state.spepage
    }
}
const mutations = {
    //列表数据
    GET_SPELIST(state,payload){
        state.spelist = payload
    },
    //总页码
    GET_ZONGYE(state,payload){
        state.spezongye = payload
    },
    //当前页码
    GET_PAGE(state,payload){
        state.spepage = payload
    }
}
const actions = {
    //封装一个获取列表的行动
   getSpeList(context,payload){
    //在这边做了一个简易的判断 payload是空值 是从商品管理传过来的 因为那边需要全部数据不需要分页
    let params = {
        size: context.state.spesize,
        page: context.state.spepage
    }
    //如果从商品管理传过来的数据有值得话就 就走没有页码的值 否则的话就走有页码的值
    if (payload) {
        params.size = payload.size;
        params.page = payload.page
    }
    //调用菜单数据接口
    specslist(params).then(res => {
        if (res.code == 200) {
            context.commit('GET_SPELIST',res.list)
            //正常情况下点击删除没有问题但是除了第一=页 删除最后一个的时候不会实时更新 而且也要判断如果是第一页就不执行改代码
            let list = res.list?res.list:[]
            if (context.state.spepage!=1 && list.length == 0) {
                context.dispatch('changePages',context.state.spepage-1)
            }
           
        }
    })
   },
   //封装一个分页的总数
   async getspecscount({commit}){
    let res = await specscount()
    commit('GET_ZONGYE',res.list[0].total)
   },

   //封装一个拿到当前页码的方法
   changePages(context, payload) {
    console.log(payload);
    //修改页码
    context.commit('GET_PAGE', payload)
    //重新调用数据请求
    context.dispatch('getSpeList')
}
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  