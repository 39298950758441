<template>
    <div>
        <div class="sc-r9hgzb-0 fosfk" style="height: 100%;">
            <div>
                <div data-cy="headerDesktop" class="sc-1qkjd88-0 gPMtgp1">
                    <div class="sc-1ixwe48-0 epqoFa adBanner"></div>
                    <div class="sc-1n5lfdf-0 sc-1qkjd88-1 uJoWq gvnIso">
                    </div>
                    <div class="sc-1qkjd88-2 jihWxA">
                        <div class="sc-wfit35-0 dJzMTK">
                            <div class="sc-pxhd6m-1 cNQvsF logo inline-center">
                                <a @click="toindex" class="sc-1efpob3-0 eccJFs" href="javascript:;">
                                    <img id="header-logo" src="../assets/index/image/logo.png" alt="农牧业B2B在线交易平台"
                                        class="sc-pxhd6m-0 jTbbzt" />
                                </a>
                            </div>
                            <div class="sc-1c65rd0-0 itGLaC search-Box" data-cy="header_search">
                                <!-- <form autoComplete="off" class="dahezi"> -->
                                <div class="sc-1c65rd0-5 giNrsO">
                                    <input @keyup.enter="search" v-model="search_name" type="text" name="searchTerms"
                                        value="" placeholder="搜索产品、品牌..." autoComplete="off" role="textbox"
                                        aria-autocomplete="list" aria-haspopup="true" class="sc-1c65rd0-6 evwBTz" />

                                    <i class="sc-1c65rd0-8 iJwHdH"></i>
                                    <div class="sc-1c65rd0-9 eUSTKu"></div>
                                    <div class="sc-1c65rd0-7 bfJiOc input-group-item header-search-buttons">
                                        <div @click="search" type="submit" class="sc-1c65rd0-1 bQaXTP">
                                            <i class="sc-1c65rd0-2 sc-1c65rd0-3 gwvmtR cjEtCg fa fa-search"></i>
                                        </div>
                                    </div>


                                </div>

                                <div>
                                    <div data-cy="autocomplete" class="sc-gex62n-0 fitVcM">
                                        <div class="sc-gex62n-1 jktpOX"></div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                            <div class="sc-ztyvp1-0 sc-9w44si-0 ghTpsN wXBMk">
                                <ul class="sc-19cvg16-0 fXamiV">
                                    <li @click.prevent="chanpin" class="sc-1yh1py6-0 kZjAPO haver">
                                        <!-- 鼠标划过显示 -->

                                        <a class="sc-1n4acrd-0 sc-9w44si-2 hYVWig gSnGLf"
                                            data-cy="directLinkHeader_PRODUCTS" href="">
                                            产品
                                            <!-- <i class="sc-16f9576-0 sc-9w44si-4 jlaoeo dtLdCU fa fa-angle-down"></i> -->
                                        </a>
                                    </li>
                                    <li @click.prevent="PDFindex" class="sc-1yh1py6-0 kZjAPO">
                                        <div class="sc-9w44si-3 iciNpP collapsed"></div>
                                        <a class="sc-1n4acrd-0 sc-9w44si-2 kROMxW gSnGLf"
                                            data-cy="directLinkHeader_CATALOGS" href="">PDF 产品目录</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div style="margin:auto;width:100%;max-width:1920px">
                        <!-- <div class="sc-1w7yr6g-1 hgsGmi adBr" id="header-br" data-cy="banners"></div> -->
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            search_name: '',//搜索框内容
            isShow: false,//搜索框判断
            logo:'',
            search_list: [
                {
                    id: 1,
                    title: "拖拉机"
                },
                {
                    id: 2,
                    title: "收割机"
                },
                {
                    id: 3,
                    title: "蔬菜收割机"
                },
                {
                    id: 4,
                    title: "除草机"
                }
            ]
        }
    },
    methods: {
        //搜索点击
        search() {
            if (this.search_name == '') {
                this.$message.error('请输入搜索内容');
                return
            }
            this.$router.push(`/Product_type?searchname=${this.search_name}`)
        },
        chanpin() {
            this.$router.push('/index');
        },
        PDFindex() {
            this.$router.push('/PDF');
        },
        toindex() {
            this.$router.push('/index');
        }
    },
    mounted() {
        const storedData = JSON.parse(localStorage.getItem('Siteinformation'));
        this.logo = storedData.logo
    },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="less">
@import "../assets/index/css/index.css";

.giNrsO {
    position: relative;

    .changyong {
        position: absolute;
        top: 0;
        width: 98.5%;
        top: 35px;
        background-color: #fff;
        height: 450px;
        z-index: 99999;
        padding: 20px 0 1px 10px;
        box-sizing: border-box;
    }

    .changyong div {
        font: 16px / 18px Arial;
        letter-spacing: 0px;
        color: rgb(153, 153, 153);
        // margin-bottom: 20px;

    }

    .changyong ul li {
        width: 100%;
        font: 16px / 18px Arial;
        color: rgb(85, 85, 85);
        cursor: pointer;
        line-height: 30px;
        margin-top: 10px;
        padding: 5px;
    }

    .changyong ul li:hover {
        background-color: #F1F1F1;
    }
}

.zhezhao {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.dJzMTK {
    padding-top: 30px;
}
</style>