<template>
    <div>
        <!-- 平板显示头部    小于1025-->
        <div v-if="isTablet">
            <div class="ipad_sj">
                <!-- 左侧 -->
                <div class="ipad_left">
                    <!-- 菜单 -->
                    <div>
                        <!-- 菜单按钮 -->
                        <div>
                            <img @click="menukaiqi" class="ipad_menuimg" src="../assets/image/caidan.png" alt="">
                        </div>
                    </div>

                    <!-- 中间 -->
                    <div>
                        <!-- logo -->
                        <div @click="toindex">
                            <img class="ipad_logo" :src="logo" alt="">
                        </div>
                    </div>
                </div>
                <!-- 右侧 -->
                <div>
                    <!-- 搜索按钮 -->
                    <div>
                        <img @click="tosearch" class="ipad_search" src="../assets/image/search.png" alt="">
                    </div>
                </div>
            </div>
            <!-- 菜单 -->
            <!-- leftmenu -->
            <div :class="{ 'leftmenu': menuisShow, 'leftmenu1': !menuisShow }">
                <img class="guanbi1" @click="guanbimenu" src="../assets/image/back.png" alt="">
                <ul class="ulaaaa">
                    <li @click="chanpin">
                        <div>产品</div><img style="width: 20px;height: 20px;" src="../assets/image/rightbai.png" alt="">
                    </li>
                    <li @click="PDFindex">
                        <div>PDF产品目录</div><img style="width: 20px;height: 20px;" src="../assets/image/rightbai.png" alt="">
                    </li>
                </ul>
            </div>
            <!-- 搜索栏 -->
            <transition name="slide-fade">
                <div v-if="isShowsearch" class="search-box">
                    <div class="box8">
                        <div class="box9">
                            <div class="searcg_box1">
                                <img style="width: 23px; height: 23px;" src="../assets//image/sousuo.png" alt="">
                                <input v-model="search_name" class="tosearch" type="text" placeholder="搜索产品、品牌">
                            </div>

                            <img class="guanbi" @click="toggleSearchBox" src="../assets/image/back.png" alt="">
                        </div>

                        <!-- 搜索内容 -->
                        <div class="chang">
                            <div class="a" v-if="search_name" @click="Go_toSearch">
                                <div class="b"> 在产品目录中搜索 "{{ search_name }}"</div><img src="../assets/image/right.png"
                                    style="width: 20px; height: 20px;" alt="">
                            </div>
                            <div>
                                <div>常用搜索</div>
                                <ul>
                                    <li @click="lisearch(item.title)" v-for="(item, index) in changyong" :key="index">{{
                                        item.title }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            search_name: '',
            logo: '',
            isWideScreen: false,
            isTablet: false,
            isShowsearch: false,
            menuisShow: false,
            changyong: [
                {
                    title: '拖拉机'
                },
                {
                    title: '割草机'
                },
                {
                    title: '喷雾机'
                },
            ]
        }
    },
    created() {
        // 添加窗口宽度变化的事件监听器
        window.addEventListener("resize", this.checkScreenWidth);
        // 在组件创建时初始化一次屏幕宽度状态
        this.checkScreenWidth();
    },
    destroyed() {
        // 在组件销毁时移除事件监听器，以防止内存泄漏
        window.removeEventListener("resize", this.checkScreenWidth);
    },
    methods: {
        //打开菜单
        menukaiqi() {
            this.menuisShow = true
        },
        //关闭左侧菜单栏
        guanbimenu() {
            this.menuisShow = false
        },
        //缩放部分ipad
        tosearch() {
            this.isShowsearch = true
            this.searchheight = 500
        },
        toggleSearchBox() {
            this.isShowsearch = false
        },
        lisearch(title) {
            this.$router.push(`/Product_type?searchname=${title}`)
        },
        Go_toSearch() {
            this.search()
        },


        //缩放部分ipad

        checkScreenWidth() {
            // 根据窗口宽度更新状态
            this.isWideScreen = window.innerWidth >= 1026;
            this.isTablet = window.innerWidth < 1025;
        },
        search() {
            if (this.search_name == '') {
                this.$message.error('请输入搜索内容');
                return
            }
            this.$router.push(`/Product_type?searchname=${this.search_name}`)
        },
        chanpin() {
            this.$router.push('/index');
        },
        PDFindex() {
            this.$router.push('/PDF');
        },
        toindex() {
            this.$router.push('/index');
        }
    },
    mounted() {
        console.log(this.isWideScreen, '1');
        console.log(this.isTablet, '2');
        const storedData = JSON.parse(localStorage.getItem('Siteinformation'));
        this.logo =  storedData.logo
    },
    watch: {},
    filters: {},
    components: {}
}
</script>
<style scoped>
@media (max-width: 1025px) {

    /* 添加平板电脑下的样式 */
    .ipad_sj {
        /* width: 96.5%; */
        background-color: #3A3A3A;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center
            /* align-items: ; */
    }

    .ipad_menuimg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-top: 3px;
    }

    .ipad_logo {
        height: 42px;
    }

    .ipad_left {
        height: 100%;
        display: flex;
    }

    .ipad_search {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }

    /* 搜索框样式 */
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }

    .search-box {
        padding: 30px 10px;
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .searcg_box1 {
        width: 80%;
        height: 40px;
        border: 2px solid #2b79ee;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding-left: 5px;
        border-radius: 3px;
    }

    .tosearch {
        border: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .guanbi {
        width: 20px;
        height: 20px;
    }

    .guanbi1 {
        width: 18px;
        height: 18px;
        float: right;
        /* text-align: right; */
    }

    .box9 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        /* padding-bottom: 30px; */
        padding: 0 20px 30px 20px;
    }

    .chang {
        padding: 10px 20px;
    }

    .chang div {
        color: #999999;
        font-size: 15px;
    }

    .chang ul {
        margin-top: 20px;
        line-height: 50px;
    }

    .chang ul li {
        font-size: 13px;
    }

    .chang ul li:hover {
        background-color: #F1F1F1;
        padding: 0 5px;
    }

    .chang .a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #2b79ee;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .chang .a .b {
        color: #2b79ee;
        font-size: 15px;
    }

    .leftmenu {
        transition: all 0.3s ease-in-out 0s;
        width: 75%;
        max-width: 350px;
        margin-left: 0;
        padding: 30px 15px 0 15px;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #2A2A2A;
        z-index: 9999;
        overflow: hidden;
    }

    .leftmenu1 {
        transition: all 0.3s ease-in-out 0s;
        width: 75%;
        max-width: 350px;
        margin-left: max(-900px, -85%);
        padding: 30px 15px 0 15px;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #2A2A2A;
        z-index: 9999;
        overflow: hidden;
    }

    .ulaaaa{
        line-height: 50px;
        margin-top: 50px;
    }

    .ulaaaa li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }

    .ulaaaa li div {
        color: #fff;
        font-size: 15px;
    }

}
</style>
