//引入菜单列表数据
import { goodslist, goodscount } from '../../../requers/api'
const state = {
    comlist: [],
    comzongye: 0, //总条数
    spesize: 3,//当前页显示多少条数据
    spepage: 1, //当前显示页

}
const getters = {
    getCom(state) {
        return state.comlist
    },
    getZy(state) {
        return state.comzongye
    },
    getSize(state) {
        return state.spesize
    },
    getPages(state) {
        return state.spepage
    }
}
const mutations = {
    GET_ROLELIST(state, payload) {
        state.comlist = payload
        // console.log(state.rolelist)
    },
    GET_ZONGYE(state, payload) {
        state.comzongye = payload
    },
    //当前页码
    GET_PAGE(state, payload) {
        state.spepage = payload
    }
}
const actions = {
    //封装一个获取列表的行动 需要传两个参数
    getComList(context) {
        //调用菜单数据接口
        goodslist({
            size: context.state.spesize,
            page: context.state.spepage,
        }).then(res => {
            if (res.code == 200) {
                //正常情况下点击删除没有问题但是除了第一=页 删除最后一个的时候不会实时更新 而且也要判断如果是第一页就不执行改代码
                let list = res.list ? res.list : []
                if (context.state.spepage != 1 && list.length == 0) {
                    context.dispatch('changePages', context.state.spepage - 1)
                }
                context.commit('GET_ROLELIST', res.list)
            } else {
            }
        })
    },
    //封装一个总页码的方法
    getZongye({ commit }) {
        goodscount().then(res => {
            commit('GET_ZONGYE', res.list[0].total)
        })
    },

    //拿到当前页码的方法
    changePages(context, payload) {
        //修改页码
        context.commit('GET_PAGE', payload)
        //重新调用数据请求
        context.dispatch('getComList')
    }
}
export default {
    state,
    getters,
    mutations,
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced: true
}
