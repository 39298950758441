import { formToJSON } from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
//引入仓库内容
import store from '../store'


Vue.use(VueRouter)
export const IndexRoutes = [
  // {
  //   path: '/list_details',
  //   component:() => import('../view/list_details.vue'),
  //   name:'列表详情'
  // }
 
]

const routes = [
  {
    path: '*',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('../pages/index.vue'),
    children: [
    //  ...IndexRoutes
    ]
  },
  {
    path:'/demo',
    component:() => import('../pages/demo.vue')
  },
  {
    path:'/PDF',
    component:() => import('../pages/PDF_index.vue')
  },
  {
    path:'/index_details',
    component:() => import('../view/index_details.vue')
  },
  {
    path: '/list_details',
    component: () => import('../view/list_details.vue'),
    name: '列表详情'
  },
  {
    path: '/Product_type',
    component: () => import('../view/Product_type.vue'),
    name: '商品规格'
  },
  {
    path:'/ceshi',
    component:() => import('../view/ceshi.vue'),
    name:'测试'
  },
  {
    path:'/sort',
    component:() => import('../view/sort.vue'),
    name:'分类'
  },
  {
    path:'/Listofbrands',
    component:() => import('../view/Listofbrands.vue'),
    name:'品牌列表'
  },
  {
    path:'/caigou',
    component:() => import('../view/caigou.vue'),
    name:'采购页面'
  },
  {
    path:'/dan',
    component:() => import('../view/dan.vue'),
    name:'单页面'
  },
  {
    path:'/pdfsort',
    component:() => import('../view/pdfsort.vue'),
    name:'pdf分类'
  },
  {
    path:'/tijiao',
    component:() => import('../view/tijiao.vue'),
    name:'提交页面'
  },
  {
    path:'/wenti',
    component:() => import('../view/wenti.vue'),
    name:'问题页面'
  },
  {
    path:'/yewu',
    component:() => import('../view/yewu.vue'),
    name:'业务页面',
    beforeEnter: (to, from, next) => {  
      // 在进入页面时执行滚动到顶部的操作  
      window.scrollTo(0, 0);  
      next();  
    }  
  },
  {
    path:'/swiper',
    component:() => import('../view/swiper.vue'),
    name:'demo轮播'
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   // Check if the target route is '/Product_type'
//   if (to.path === '/Product_type') {
//     // Reload the page to apply any necessary changes
//     location.reload();
//   }

//   // Continue with the navigation
//   next();
// });


export default router
