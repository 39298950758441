//引入菜单列表数据
import {catelist} from '../../../requers/api'
const state = {
    catelist:[]
}
const getters = {
    getCate(state){
        return state.catelist
    }
}
const mutations = {
    GET_CATELIST(state,payload){
        state.catelist = payload
        // console.log(state.menulist)
    }
}
const actions = {
    //封装一个获取列表的行动
    async getCateList({commit}){
        let res = await catelist({
            istree:true
        });
        commit('GET_CATELIST',res.list)
    }
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  