<template>
    <div>
        <div @click="fanhui" class="fanhui">&#8810; 返回上一页</div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        fanhui(){
            this.$router.go(-1)
        },
    },
    mounted() { },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped>
.fanhui{
    display: block;
    font-size: 15px;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000;
}
</style>