//引入菜单列表数据
import {menuList} from '../../../requers/api'
const state = {
    menulist:[]
}
const getters = {
    getMenu(state){
        return state.menulist
    }
}
const mutations = {
    GET_MENULIST(state,payload){
        state.menulist = payload
        // console.log(state.menulist)
    }
}
const actions = {
    //封装一个获取列表的行动
   getMenuList({commit}){
    //调用菜单数据接口
    menuList(
       {
        istree:true
       }
    ).then(res => {
        if (res.code == 200) {
            // console.log('菜单数据获取成功');
            commit('GET_MENULIST',res.list)
        }
    })
   }
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  