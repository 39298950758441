// 引入axios
import axios from 'axios'

//引入 'querystring'  主要作用是转换请求头的格式
import qsString from 'querystring'
//登录模块
// import router from '../router'


//设置拦截器
//请求拦截器
axios.interceptors.request.use(req => {
    return req
})
//响应拦截器
axios.interceptors.response.use((res) => {
    return res.data
})


/*
es6的导入导出
导出的方式影响了导入方式
导出 export default 变量
导入  import 自定义 from '位置'
导出 export  变量
导入  import {变量} from '位置'
*/

//封装请求方式 常用的就是  get / post
/*
get
传参
url：后端提供的地址
params 参数对象
*/


//封装get请求方法
export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        //调用axios中的get方法
        axios.get(url, {
            params
        })
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}


/* 
post 
传参
url：后端提供的地址
params 参数对象
isFile 是否有上传文件
*/
/* 
post 方式比较特殊
后端（java/php）一般要求我们的传入内容（content-type）格式要有所变化
没有转化之前：
content-type: application/json;
一般后端都会要求格式，默认格式
content-type:  application/x-www-form-urlencoded
默认。在发送前对所有字符进行编码（将空格转换为 "+" 符号，特殊字符转换为 ASCII HEX 值）。
但是！！！！一旦有上传文件，格式类型 必须改成 multipart/form-data！！！！
content-type:  multipart/form-data
*/
//封装POST请求方法
export const post = (url, params = {}, isFile = false) => {
    //设置一个空对象
    let data = {}
    if (isFile) {
        //有上传文件 要修改content-type
        data = new FormData()
        for (let i in params) {
            data.append(i, params[i])
        }
    } else {
        data = qsString.stringify(params)
    }
    return new Promise((resolve, reject) => {
        //调用axios的get
        axios.post(url, data)
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}