import { get, post } from './index'

//首页站点信息
export const Homesite = data => get('/api/home/about/site_info')
//首页产品(新品)
export const HomeList = data => get('/api/home/index/product_list')
//首页一级二级分类
export const Homesort = data => get('/api/home/product/product_cate')
//通过二级分类获取三级分类  请求参数 id:id
export const sanjifenlei = (data) =>get('/api/home/product/product_son_cate',data)
//通过分类/品牌获取产品列表
export const Categorylist = data => get('/api/home/product/product_lists')
//产品列表 
export const Productlist = (data) =>get('/api/home/product/product_lists',data)
//产品品牌列表 
export const Productlists = (data) => get('/api/home/product/product_brand_lists',data)
//产品详情
export const Productdetails = (data) => get('/api/home/product/product_info',data)
//品牌列表无分页
export const Product = data => get('/api/home/product/product_brand')

//添加留言   请求参数 name：姓名  phone：手机号  body：内容
export const Leavemessage = (data) =>post('/api/home/index/create_feedback',data)
//采购商品 问题
export const Wenzhang = (data) =>get('/api/home/news/news_info',data)


//PDF首页详情
export const PDFsize = data => get('/api/home/index/product_p_cate')
//PDF首页列表
export const PDFlist = (data) => get('/api/home/product/product_pdf_lists',data)
//PDF详情页
export const PDFdetail = (data) => get('/api/home/product/product_pdf_info',data)










