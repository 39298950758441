import {memberlist} from '../../../requers/api'
const state = {
    memlist:[]
}
const getters = {
    getMem(state){
        return state.memlist
    }
}
const mutations = {
    GET_MEMLIST(state,payload){
        state.memlist = payload
        // console.log(state.rolelist)
    }
}
const actions = {
    //封装一个获取列表的行动
   getMemList({commit}){
    //调用菜单数据接口
    memberlist().then(res => {
        if (res.code == 200) {
            console.log(res,'会员管理数据回去成功');
            commit('GET_MEMLIST',res.list)
        }else{
            console.log('获取失败');
        }
    })
   }
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  