import  breadcrumb  from "./breadcrumb";
import dibu from "./footer"
import indexnavber from './indexnavber'
import carouselmap from './carouselmap'
import navbar from './navbar'
import fanhui from './fanhui'
import toubu from './toubu'
import ipattoubu from './ipad'
export default{
    breadcrumb,
    dibu,
    indexnavber,
    carouselmap,
    navbar,
    fanhui,
    toubu,
    ipattoubu
}