import { userList, userCount } from '../../../requers/api'
//存储数据
const state = {
    //数据存储
    userlist: [],
    //数据总数量
    count: 0,
    //当前页码显示多少条数据
    pageSize: 3,
    //页码
    pageNum: 1
    //分析当前页码需要多少数据 是固定死的不需要传值


    //需要传值的参数为
    //1. 总条数  count 就是说总数据有多少条

    //2. 当前页码 需要在点击之后将当前页码传过来
}
//唯一修改数据的地方
const mutations = {
    //第一个参数指向的是仓库  第二个参数指向的接受的参数
    GET_USERLIST(state, payload) {
        state.userlist = payload
    },
    //总数据的条数
    GET_COUNT(state, payload) {
        state.count = payload
    },
    //当前页码
    GET_PAGE(state, payload) {
        state.pageNum = payload
    }
}
//合并的地方
const getters = {
    getUser(state) {
        return state.userlist
    },
    getCount(state) {
        return state.count
    },
    getPageSize(state) {
        return state.pageSize
    }
}
//获取数据的地方
const actions = {
    //封装一个获取数据的方法
    getUserList(context) {
        userList(
            {
                size: context.state.pageSize,
                page: context.state.pageNum
            }
        ).then((res) => {
            //修改 第一个参数自定义属性名  第二个参数需要传的值
            if (res.code == 200) {
                /* 
                  在正常情况下获取数据都是没问题的但是当在当前页点击删除最后一页的数据之后就会出现暂无数据的显示并不会重新加载 那是因为他依然获取的是当前页码导致了当前页码数据为空但是他依然请求

                  解决方法：当页码是最后一页的时候需要不需要执行此判断  判断当数据为空的时候当前页码-1
                 */
                //下面代码的意思是目的是： 后端返回的数据格式并不友好，正常无数据的时候，应该返给前端[]但是它返回了null null没办法判断所以进行转化
                let list = res.list ? res.list : [];
                if (context.state.pageNum != 1 && list.length == 0) {
                   context.dispatch('changePages',context.state.pageNum-1)
                }
                context.commit('GET_USERLIST',list)

            }
        })
    },
    //总条数的方法
    async getUserCount({ commit }) {
        let res = await userCount();
        //获取到数据之后
        commit('GET_COUNT', res.list[0].total)
        console.log(res);
    },
    //当前页码的方法
    /* 
    context调用之后其中的方法有
       1.commit
       2.dispatch
       3.getters
       4.rootGetters
       5.rootState
       6.state
    */
    changePages(context, payload) {
        //修改页码
        context.commit('GET_PAGE', payload)
        //重新调用数据请求
        context.dispatch('getUserList')
    }

}

export default {
    state,
    mutations,
    getters,
    actions,
    //开启命名空间
    namespaced: true

}