//引入菜单列表数据
import {secklist} from '../../../requers/api'
const state = {
    seckList:[]
}
const getters = {
    getSeck(state){
        return state.seckList
    }
}
const mutations = {
    GET_SECKLILIST(state,payload){
        state.seckList = payload
    }
}
const actions = {
    //封装一个获取列表的行动
    async getSeckList({commit}){
        let res = await secklist({});
        commit('GET_SECKLILIST',res.list)
    }
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  