import Vue from 'vue'
import Vuex from 'vuex'
//封装好的模块菜单列表模块
import menu from './modules/menu'
import role from './modules/role'
import manage from './modules/manage'
import cate from './modules/cart'
import spe from './modules/specification'
import com from './modules/CommodityManagement'
import mem from './modules/member/index'
import ban from './modules/bannlist/index'
import sec from './modules/seckill/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userlogin: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
  },
  getters: {
    getLogin(state) {
      return state.userlogin
    }
  },
  mutations: {
    POST_LOGIN(state, payload) {
      //因为仓库一刷新就会回到初始值然后要存储到本地缓存
      if (payload) {
        state.userlogin = payload
        sessionStorage.setItem('userInfo', JSON.stringify(payload))
      } else {
        //清空
        sessionStorage.removeItem('userInfo')
      }
    }
  },
  actions: {
    //登录
    ChangeLogin(context, payload) {
      context.commit('POST_LOGIN', payload)
    }
  },
  modules: {
    menu,
    role,
    manage,
    cate,
    spe,
    com,
    mem,
    ban,
    sec
  }
})
