<template>
  <div class="dibufengzhuang">
    <!-- 文字 -->

    <div class="jeishi" v-if="isWideScreen">
      <div class="liebiao" @click="caigou">
        如何采购商品？
      </div>
      <div class="liebiao" @click="list">
        品牌列表
      </div>
      <div class="liebiao" @click="dan(1)">
        联系方式
      </div>
      <div class="liebiao1" @click="dan(2)">
        企业介绍
      </div>
    </div>
    <div class="jeishi" v-if="isTablet">
      <div class="liebiao" @click="caigou">
        如何采购商品？
      </div>
      <div class="liebiao" @click="list">
        品牌列表
      </div>
      <div class="liebiao" @click="dan(1)">
        联系方式
      </div>
      <div style="border-bottom: 0;" class="liebiao" @click="dan(2)">
        企业介绍
      </div>
    </div>
    <div class="dibu">
      © 2023 版权所有 - <span class="tiaokuan" @click="dan(3)">法律条款</span> - <span @click="beian" class="tiaokuan">{{
        banquan.icp }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isHovered: false,
      banquan: [],
      isWideScreen: false,
      isTablet: false,
    }
  },
  created() {
    // 添加窗口宽度变化的事件监听器
    window.addEventListener("resize", this.checkScreenWidth);
    // 在组件创建时初始化一次屏幕宽度状态
    this.checkScreenWidth();
  },
  destroyed() {
    // 在组件销毁时移除事件监听器，以防止内存泄漏
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      // 根据窗口宽度更新状态
      this.isWideScreen = window.innerWidth >= 1026;
      this.isTablet = window.innerWidth < 1025;
    },
    //去备案网站
    beian() {
      const externalLink = 'https://beian.miit.gov.cn/';
      window.location.href = externalLink;
    },
    list() {
      this.$router.push('/Listofbrands')
    },
    //去采购页面
    caigou() {
      window.scrollTo(0, 0); // 将页面滚动到顶部
      this.$router.push(`/caigou?type=${1}`)
    },

    //单页面
    dan(id) {
      window.scrollTo(0, 0); // 将页面滚动到顶部
      if (id == 1) {
        this.$router.push(`/wenti?type=${2}`)
      } else if (id == 2) {
        this.$router.push(`/yewu?type=${3}`)
      } else if (id == 3) {
        this.$router.push(`/dan?type=${4}`)
      }
    }
  },
  mounted() {
    this.banquan = JSON.parse(localStorage.getItem('Siteinformation'))//站点信息
  },
  watch: {},
  computed: {},
  filters: {},
  components: {}
}
</script>
<style scoped lang="less">
@media (min-width: 1026px) {
  .dibufengzhuang {
    margin-top: 70px;

    .tishi {
      margin-bottom: 10px;
      text-align: center;
      font-size: .91em;
      color: #999
    }

    .jeishi {
      background-color: #555555;
      width: 100%;
      height: 170px;
      display: flex;
      justify-content: center;
    }

    .liebiao {
      height: 100%;
      width: 15%;
      background-color: #4D4D4D;
      color: #fff;
      text-align: center;
      line-height: 170px;
      position: relative;
      cursor: pointer;
    }

    .liebiao:hover {
      color: #A1A1A1;
    }

    .liebiao1:hover {
      color: #A1A1A1;
    }

    .liebiao1 {
      height: 100%;
      width: 15%;
      background-color: #4D4D4D;
      color: #fff;
      text-align: center;
      line-height: 170px;
      position: relative;
      cursor: pointer;
    }

    .liebiao::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0.5px;
      height: 50px;
      background-color: #7A7A7A;
    }

    .dibu {
      width: 100%;
      height: 80px;
      text-align: center;
      line-height: 80px;
      color: #717171;
      background-color: #424242;
    }

    .tiaokuan {
      cursor: pointer;
    }

    .tiaokuan:hover {
      color: #A1A1A1;
    }
  }
}


@media (max-width: 1025px) {
  .dibufengzhuang {
    margin-top: 30px;

    .tishi {
      margin-bottom: 10px;
      text-align: center;
      font-size: .91em;
      color: #999;
       padding: 0 20px;
    }

    .jeishi {
      background-color: #555555;
      width: 100%;
      // height: 170px;
      // display: flex;
      // justify-content: center;
     
    }

    .liebiao {
      width: 100%;
      background-color: #555555;
      color: #fff;
      text-align: center;
      line-height: 30px;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid #7A7A7A;
    }

    .liebiao:hover {
      color: #A1A1A1;
    }

    .liebiao1:hover {
      color: #A1A1A1;
    }

    .liebiao1 {
      height: 100%;
      width: 15%;
      background-color: #4D4D4D;
      color: #fff;
      text-align: center;
      line-height: 170px;
      position: relative;
      cursor: pointer;
    }

    .liebiao::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0.5px;
      height: 50px;
      background-color: #7A7A7A;
    }

    .dibu {
      width: 100%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: #717171;
      background-color: #424242;
    }

    .tiaokuan {
      cursor: pointer;
    }

    .tiaokuan:hover {
      color: #A1A1A1;
    }
  }

}
</style>
