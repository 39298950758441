<template>
    <div>
        <div class="header" id="header">
            <div class="header-top container-wide" id="header-content">
            </div>
            <div class="header-nav container-wide">
                    <div class="logo inline-center">
                        <a @click="toindex" href="javascript:;">
                            <img id="header-logo" :src="logo" alt="农牧业B2B在线交易平台">
                        </a>
                    </div>
                    <div class="search col-lg-4 col-md-4 col-sm-6 col-xs-8">
                        <div id="header-search" class=" header-search" data-searchmax="12" data-lastsearchmax="5"
                            data-companysearchmax="5">
                            <div id="dimmer-search" style="display: none;"></div>
                            <div class="input-group">
                                <input v-model="search_name" @keyup.enter="search" class="input ui-autocomplete-input"
                                    type="text" name="searchTerms" value="" autocomplete="off" placeholder="搜索产品、品牌..."
                                    role="textbox" aria-autocomplete="list" aria-haspopup="true">
                                <i class="fa fa-search mobile-search-icon"></i>
                                <div @click="search" class="input-group-item header-search-buttons">
                                    <div id="header-search-delete"></div>
                                    <button id="header-search-launch-button" class="btn"><i
                                            class="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul style="margin-top:0px;" class="nav col-lg-6 col-md-6 col-sm-12 col-xs-12 right" id="header-nav">
                        <li id="header-products">
                           <a class="shouye"   @click.prevent="chanpin" href="">产品
                            <!-- <i class="fa fa-angle-down"></i> -->
                        </a>
                        </li>
                        <li @click.prevent="PDFindex" id="header-catalogs" class="actif">
                            <a class="actif1 PDF"
                                href="https://pdf.agriexpo.cn/">PDF 产品目录</a>
                        </li>
                    </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            search_name: '',
            logo:'',
        }
    },
    methods: {
        search() {
            if (this.search_name == '') {
                this.$message.error('请输入搜索内容');
                return
            }
        this.$router.push(`/Product_type?searchname=${this.search_name}`)
        },
        chanpin() {
            this.$router.push('/index');
        },
        PDFindex() {
            this.$router.push('/PDF');
        },
        toindex() {
            this.$router.push('/index');
        }
    },
    mounted() {
        const storedData = JSON.parse(localStorage.getItem('Siteinformation'));
        this.logo =  storedData.logo
     },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped>
.nav>li>a:hover {
    /* color: #2b79ee;  */
}
.shouye{
    font-weight: bold;
}

.nav>li>.PDF{
    font-weight: bold;
    color:#fff ;
    background-color:#2b79ee;
    border-radius: 4px;    
}


</style>
<style scoped>
@import "../assets/PDF/css/accueil_pdf.css";
@import "../assets/PDF/css//agriexpo.css";
@import "../assets/PDF/css/global.css";
 </style>