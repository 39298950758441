//引入菜单列表数据
import {bannerlist} from '../../../requers/api'
const state = {
    bannerList:[]
}
const getters = {
    getBann(state){
        return state.bannerList
    }
}
const mutations = {
    GET_BANNERLIST(state,payload){
        state.bannerList = payload
    }
}
const actions = {
    //封装一个获取列表的行动
    async getBannerList({commit}){
        let res = await bannerlist({});
        commit('GET_BANNERLIST',res.list)
    }
}
export default{
    state,
    getters,
    mutations,                                       
    actions,
    //开启命名空间  在store中获取到的就是(menu/getMenu)否则的话即使(getMenu)如果多个仓库的话就会重名
    namespaced:true
  }
  